import { createSlice } from '@reduxjs/toolkit';
import { initialPaginated } from 'constants/states';
import { prepareUpdatedData } from 'helpers/listValues';
import { AppDispatch, RootState } from 'redux/store';
import {
  createInvitation,
  deleteInvitation,
  getInvitations
} from 'requests/invitations';
import { enqueueSnackbar } from './notifications';

const initialState: any = {
  filters: {
    page: 1,
    limit: 4,
    offset: 0,
    role: ''
  },
  response: initialPaginated,
  loading: false,
  subLoading: false
};

const slice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setSubLoading(state, action) {
      state.subLoading = action.payload;
    },
    setFilter(state, action) {
      state.filters = {
        ...state.filters,
        ...action.payload
      };
    },
    getInvitationsSuccess(state, action) {
      state.response = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const getInvitationsData =
  (params: { limit: number; offset: number; role?: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    getInvitations(params).then((res) => {
      dispatch(slice.actions.getInvitationsSuccess(res));
      dispatch(slice.actions.setLoading(false));
    });
  };

export const setInvitationsFilter =
  (field: string, value: any) => (dispatch: AppDispatch) => {
    dispatch(
      slice.actions.setFilter({
        [field]: value
      })
    );
  };

export const addInvitation =
  (data: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setSubLoading(true));
    createInvitation(data)
      .then((res) => {
        if (res) {
          const { invitations } = getState();
          dispatch(
            slice.actions.getInvitationsSuccess(
              prepareUpdatedData('create', invitations.response, res)
            )
          );
        }
        return res;
      })
      .then((res) => {
        if (res) {
          dispatch(
            enqueueSnackbar({
              messageType: 'created',
              options: { variant: 'success' }
            })
          );
        }
        dispatch(slice.actions.setSubLoading(false));
      });
  };

export const removeInvitation =
  (id: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(slice.actions.setSubLoading(true));
    deleteInvitation(id)
      .then(() => {
        const { invitations } = getState();
        dispatch(
          slice.actions.getInvitationsSuccess(
            prepareUpdatedData('delete', invitations.response, { id })
          )
        );
      })
      .then(() => {
        dispatch(
          enqueueSnackbar({
            messageType: 'deleted',
            options: { variant: 'success' }
          })
        );
        dispatch(slice.actions.setSubLoading(false));
      });
  };
