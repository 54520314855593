import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/containers/Page';
import TabPanel from '../../components/TabPanel';
import PageContainer from '../../components/containers/PageContainer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getShowcaseData } from 'redux/slices/showcase';
import CampaignsOverview from './CampaignsOverview';
import JobPostsMostViewed from './JobPostsMostViewed';
import JobPost from './JobPost';
import JobPostsOverview from './JobPostsOverview';

const applicantsOverview = '/static/img/icons/applicantsOverview.svg';
const applicantsOverviewActive = '/static/img/icons/applicantsOverviewActive.svg';
const campaign = '/static/img/icons/campaign.svg';
const campaignActive = '/static/img/icons/campaignActive.svg';
const jobPost = '/static/img/icons/jobPost.svg';
const jobPostActive = '/static/img/icons/jobPostActive.svg';
const mostViewedJobPosts = '/static/img/icons/mostViewedJobPosts.svg';
const mostViewedJobPostsActive = '/static/img/icons/mostViewedJobPostsActive.svg';

export default function Dashboard() {
  const { t } = useLocales();
  const { user } = useAuth();
  const { role } = user;
  const dispatch = useDispatch();

  const isAdmin = role === 'admin';

  useEffect(() => {
    dispatch(getShowcaseData());
  }, [dispatch]);

  const tabs = [
    {
      label: t('Job Posts Overview'),
      Component: <JobPostsOverview />,
      icon: applicantsOverview,
      activeIcon: applicantsOverviewActive,
      // hide: !isAdmin,
      path: 'all'
    },
    {
      label: t('Create New Job Post'),
      Component: <JobPost />,
      icon: jobPost,
      activeIcon: jobPostActive,
      // hide: !isAdmin,
      path: 'new'
    },
    {
      label: t('Most Viewed Job Posts'),
      Component: <JobPostsMostViewed />,
      icon: mostViewedJobPosts,
      activeIcon: mostViewedJobPostsActive,
      // hide: !isAdmin,
      path: 'most-viewed',
      subTitle: t('These are your most viewed job posts')
    },
    {
      label: t('My Campaigns'),
      Component: <CampaignsOverview />,
      icon: campaign,
      activeIcon: campaignActive,
      // hide: !isAdmin,
      path: 'campaigns',
      subTitle: t('Here you can view your job marketing campaigns')
    }
  ];

  return (
    <Page>
      <PageContainer>
        <TabPanel tabs={tabs} dashboard={true} />
      </PageContainer>
    </Page>
  );
}
