import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import styled from '@emotion/styled';

import { useDispatch } from 'react-redux';
import { resetMatchCandidatesFilters } from 'redux/slices/filters';
import { Search } from '@material-ui/icons';
import { PATH_DASHBOARD } from '../routes/paths';
import { DeleteButton, EditButton } from './Buttons';
import CultureProfileCharts, { ChartDescription } from './CultureProfileCharts';
import { deleteProfile } from '../requests/profile';
import { getTranslatedList } from '../constants/constants';
import useLocales from '../hooks/useLocales';

const TitleTypography = styled(Typography)(() => ({
  fontFamily: 'Rubik',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '15px',
  color: '#131417'
}));
const HrDivider = styled(Divider)(() => ({
  height: 0,
  border: '0.6px solid #C9CAD9'
}));

export const ProfileCard = ({
  item,
  type = 'culture'
}: {
  item: any;
  type: string;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const {
    DEFAULT_MESSAGE_TEXTS: { deleted, error }
  } = getTranslatedList();

  const onEdit = () => {
    if (type === 'culture') {
      navigate(PATH_DASHBOARD.culture_profile.getCultureProfileUrl(item.id));
    } else if (type === 'match') {
      navigate(PATH_DASHBOARD.candidates.getMatchProfileUrl(item.id));
    } else {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const onDelete = async () => {
    try {
      await deleteProfile(item.id);
      enqueueSnackbar(deleted, { variant: 'success' });
      navigate(0);
    } catch (_) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  const onShowCandidates = () => {
    dispatch(resetMatchCandidatesFilters());
    navigate(PATH_DASHBOARD.candidates.getMatchProfileCandidatesUrl(item.id));
  };

  const sortedPoints = useMemo(() => {
    if (item.competency_labels && item.competency_min_scores) {
      const { competency_labels, competency_min_scores } = item;
      if (!competency_labels[0].id) {
        return competency_labels
          .map((el: string, i: number) => ({
            point: competency_min_scores[i],
            label: el,
            descriptions: '',
            comparePoint: []
          }))
          .sort((a: any, b: any) => b.point - a.point);
      }
      const sortedInPoints = competency_min_scores
        .slice()
        .sort((a: any, b: any) => b.id - a.id)
        .reverse();
      return (
        competency_labels
          .slice()
          .sort((a: any, b: any) => b.id - a.id)
          .reverse()
          .map((el: any, i: number) => ({
            point: sortedInPoints[i].min_score,
            label: el.name,
            descriptions: '',
            comparePoint: []
          }))
          .sort((a: any, b: any) => b.point - a.point) || []
      );
    }
    return [];
  }, [item?.competency_labels, item?.competency_min_scores]);

  return (
    <>
      <Card className="open-job-posts-card">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px 0'
                  }}
                >
                  <Box>
                    <TitleTypography variant="h4">{item.name}</TitleTypography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {type !== 'culture' && (
                      <Search
                        onClick={onShowCandidates}
                        style={{ fontSize: 26 }}
                      />
                    )}
                    <EditButton onClick={onEdit} />
                    <DeleteButton onClick={onDelete} />
                  </Box>
                </Box>
                <HrDivider />
                <Box>
                  {item.competency_labels.length && (
                    <CultureProfileCharts
                      title={t('Competencies Score')}
                      labels={item.competency_labels}
                      points={item.competency_min_scores}
                      descriptions={item.competencies.map((el: any) => ({
                        description: el.competency.description,
                        name: el.competency.name
                      }))}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ChartDescription labels={sortedPoints} />
    </>
  );
};
