import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Alert
} from '@material-ui/core';
import MButton from '../../../components/new-designer/button/MButton';
import { getButtons } from '../../../constants/text/buttons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
  getPlans,
  subscribeCheckout,
  subscribeOnPlan,
  getSubscriptionPortal,
  getSubscription
} from '../../../requests/subscriptions';
import PaymentModal from '../../../components/forms/payment/PaymentModal';
import useLocales from '../../../hooks/useLocales';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { getCurrencyFromLocale } from 'utils/currencyAndLocale';
import { useSnackbar } from 'notistack';
import {
  CardFooter,
  CardHeader,
  CardPrice,
  InactivePrice,
  useStyles,
  cardSubscription
} from '../../../styled';
import CardField from '../../../components/CardField';
import { getTranslatedList } from 'constants/constants';
import MFilter from 'components/new-designer/fillter/fillter';
import { fDateDHM } from 'utils/formatTime';

const SubscriptionPlanList = ({ companyData: { plan_id: plan } }: any) => {
  const [isYearly, setIsYearly] = useState(false);
  const [isMyPlan, setIsMyPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { PERIOD_TYPES_BILLING } = getTranslatedList();
  const [open, setOpen] = useState(false);
  const [activeData, setActiveData] = useState<any>(null);
  const [submittedSessionId, setSubmittedSessionId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [plans, setPlans] = useState<any[]>([]);
  const [stripePromise, setStripePromise] = useState(null);
  const [period, setPeriod] = useState<string>(PERIOD_TYPES_BILLING[0].value);
  const [subscription, setSubscription] = useState({
    plan: '',
    status: '',
    lastBillingDate: 0,
    nextBillingDate: 0,
    subscription_limit: {
      current_assessments: 0,
      assessment_limit: 0,
      current_jobs: 0,
      job_limit: 0
    }
  });
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );

  const getInitialData = useCallback(async () => {
    setIsLoading(true);
    const plansData = await getPlans();
    setPlans(plansData?.plans.data);
    stripeInit(plansData.stripe_pub_key);
    const subscriptionData = await getSubscription();

    setSubscription(subscriptionData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  const handleSubmitOnPlan = async (id: string, stripe?: any) => {
    let res: any;
    if (company.if_free_trial) {
      res = await subscribeOnPlan(id);
    } else {
      res = await subscribeCheckout(id);
      setSubmittedSessionId(res.sessionId);
    }
    if (stripe && res?.sessionId) {
      await stripe
        .redirectToCheckout({ sessionId: res.sessionId })
        .then(() => enqueueSnackbar(t('Please, fill in the stripe form')));
    }
    setOpen(false);
  };

  const manageSubscription = async () => {
    const res = await getSubscriptionPortal();
    if (res?.url) {
      window.open(res.url);
    }
  };

  const stripeInit = (publishKey: string) => {
    if (publishKey) {
      const stripe: any = loadStripe(publishKey || '');
      setStripePromise(stripe);
    }
  };

  const setOption = (value: string) => {
    if (value === 'monthly') {
      setIsYearly(false);
      setIsMyPlan(false);
      setPeriod(PERIOD_TYPES_BILLING[0].value);
    } else if (value === 'yearly') {
      setIsYearly(true);
      setIsMyPlan(false);
      setPeriod(PERIOD_TYPES_BILLING[1].value);
    } else if (value === 'my plan') {
      setIsMyPlan(true);
      setPeriod(PERIOD_TYPES_BILLING[2].value);
    }
  };

  const getPlanId = (name: string, cur: string) =>
    plans?.find(
      ({ nickname, currency }: any) => nickname === name && currency === cur
    )?.id || null;

  const getPlanPrice = (name: string, cur: string, interval: string) => {
    const plan = plans?.find(
      ({ nickname, currency }: any) => nickname === name && currency === cur
    );
    let priceStr = null;
    if (plan) {
      if (interval === 'month') {
        priceStr = `${getCurrencyFromLocale(
          plan.currency.toUpperCase(),
          plan.unit_amount / 100
        )} ${t('p.m')}`;
      } else {
        priceStr = `${getCurrencyFromLocale(
          plan.currency.toUpperCase(),
          plan.unit_amount / 100
        )}`;
      }
    }
    return priceStr;
  };

  const { t } = useLocales();
  const { ACTIVE, INACTIVE, TRIALING } = getButtons();
  const subButtonText = company.if_free_trial
    ? t('Try for free!')
    : t('Get it now!');
  const currency = company.currency?.toLowerCase();

  const subscriptions = [
    {
      monthlyPlanId: getPlanId('Essential Monthly', currency),
      yearlyPlanId: getPlanId('Essential Yearly', currency),
      activeMonthly: plan === getPlanId('Essential Monthly', currency),
      activeYearly: plan === getPlanId('Essential Yearly', currency),
      title: t('Essential'),
      range: t('The perfect gateway to start with unbiased hiring'),
      points: [
        t('Userfriendly and efficient ATS'),
        t('Up to 150 assessments per year'),
        t('Including enriched talentpools'),
        t('Access to 2500+ jobboards'),
        t('Including careersite'),
        t('Online support'),
        t('Single user')
      ],
      monthlyCost: getPlanPrice('Essential Monthly', currency, 'month'),
      yearlyCost: getPlanPrice('Essential Yearly', currency, 'year'),
      buttontext: subButtonText,
      onSubscribe: handleSubmitOnPlan
    },
    {
      monthlyPlanId: getPlanId('Premium Monthly', currency),
      yearlyPlanId: getPlanId('Premium Yearly', currency),
      activeMonthly: plan === getPlanId('Premium Monthly', currency),
      activeYearly: plan === getPlanId('Premium Yearly', currency),
      accent: true,
      title: t('Premium'),
      range: t('Stay on top of your recruitment game'),
      points: [
        t('Userfriendly and efficient ATS'),
        t('Up to 1500 assessments per year'),
        t('Including enriched talentpools'),
        t('Access to 2500+ jobboards'),
        t('Including careersite'),
        t('Training & priority support'),
        t('Up to 10 users')
      ],
      monthlyCost: getPlanPrice('Premium Monthly', currency, 'month'),
      yearlyCost: getPlanPrice('Premium Yearly', currency, 'year'),
      buttontext: subButtonText,
      onSubscribe: handleSubmitOnPlan,
      primary_color: '#562C82',
      secondary_color: '#FFFFFF'
    },
    {
      monthlyPlanId: getPlanId('Advanced Monthly', currency),
      yearlyPlanId: getPlanId('Advanced Yearly', currency),
      activeMonthly: plan === getPlanId('Advanced Monthly', currency),
      activeYearly: plan === getPlanId('Advanced Yearly', currency),
      title: t('Advanced'),
      range: t('Work towards an excellent selection process'),
      points: [
        t('Userfriendly and efficient ATS'),
        t('Up to 500 assessments per year'),
        t('Including enriched talentpools'),
        t('Access to 2500+ jobboards'),
        t('Including careersite'),
        t('Online support+'),
        t('1-5 users')
      ],
      monthlyCost: getPlanPrice('Advanced Monthly', currency, 'month'),
      yearlyCost: getPlanPrice('Advanced Yearly', currency, 'year'),
      buttontext: subButtonText,
      onSubscribe: handleSubmitOnPlan
    }
  ];

  const PlansList = () => (
    <>
      <Typography variant="h2" marginTop="30px" color="#666">
        {t('INTELLIGENT RECRUITMENT STARTS TODAY!')}
      </Typography>
      <MFilter
        style={{
          marginTop: '24px',
          marginBottom: '34px',
          display: 'flex',
          justifyContent: 'center',
          width: '100% !important',
          maxWidth: '98px !important'
        }}
        billing={true}
        activeOption={period}
        options={PERIOD_TYPES_BILLING}
        setOption={setOption}
      />

      {!isMyPlan ? (
        <Grid container spacing={3} alignItems="flex-end">
          {subscriptions.map((el: any, i: number) => (
            <Grid item xs={12} md={4} key={i}>
              <Card
                style={{
                  padding: '43px 41px 42px 41px',
                  backgroundColor: el.primary_color,
                  borderRadius: '42px'
                }}
              >
                <CardHeader>
                  <Typography
                    style={{ fontSize: '38px' }}
                    variant="h4"
                    color={el.secondary_color}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontSize: '12px', opacity: 0.7 }}
                    color={el.secondary_color}
                  >
                    {el.range}
                  </Typography>
                  <CardPrice>
                    {isYearly && el.yearlyCost ? (
                      <>
                        <InactivePrice>{el.monthlyCost}</InactivePrice>
                        <Typography
                          variant="h2"
                          color={el.secondary_color ?? '#562C82'}
                        >
                          {el.yearlyCost}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        color={el.secondary_color ?? '#562C82'}
                        fontWeight={600}
                        textAlign="center"
                        fontSize="17px"
                      >
                        {el.monthlyCost}
                      </Typography>
                    )}
                  </CardPrice>
                </CardHeader>
                <CardContent style={{ padding: '0', marginBottom: '20px' }}>
                  <Box
                    sx={{
                      minHeight: 200,
                      textAlign: 'start',
                      display: 'flex',
                      alignItems: 'start',
                      rowGap: '20px',
                      flexDirection: 'column'
                    }}
                  >
                    {el.points.map((point: string, i: number) => (
                      <Typography
                        key={i}
                        fontSize="14px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '6px'
                        }}
                        color={el.secondary_color}
                      >
                        <SvgIconStyle src="/static/img/icons/subscription.svg" />{' '}
                        {point}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
                <CardFooter>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={() => {
                      setActiveData(el);
                      setOpen(true);
                    }}
                    style={{
                      backgroundColor: el.secondary_color ?? '#562C82',
                      color: el.primary_color ?? '#FFFFFF'
                    }}
                  >
                    {el.buttontext}
                  </Button>
                </CardFooter>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <SubscriptionInfoCard subscription={subscription} />
      )}
    </>
  );

  const Loader = () => (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress sx={{ mr: 2 }} />
      <Typography>{t('Checking your subscription status')}...</Typography>
    </Box>
  );

  const SubscriptionInfoCard = ({
    subscription: {
      plan,
      status,
      lastBillingDate,
      nextBillingDate,
      subscription_limit
    }
  }: {
    subscription: {
      plan: string;
      status: string;
      lastBillingDate: number;
      nextBillingDate: number;
      subscription_limit: {
        current_assessments: number;
        assessment_limit: number;
        current_jobs: number | string;
        job_limit: number | string;
      };
    };
  }) => {
    const isActive = status === 'active' || status === 'trialing';
    const lastDate = new Date(lastBillingDate * 1000);
    const nextDate = new Date(nextBillingDate * 1000);
    const fild_subscription_limit = `${subscription_limit?.current_assessments}/${subscription_limit?.assessment_limit}`;
    const fild_jobs_limit = `${subscription_limit?.current_jobs}/${subscription_limit?.job_limit}`;

    const formattedLastDate = fDateDHM(lastDate);
    const formattedNextDate = fDateDHM(nextDate);

    return (
      <Card
        sx={{ textAlign: 'start', flexDirection: 'column' }}
        style={cardSubscription}
      >
        <Box
          style={{
            background:
              'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)',
            borderRadius: '20px',
            width: '570px',
            padding: '30px'
          }}
        >
          <Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Rubik',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  color: '#131417'
                }}
              >
                {t('Subscription')}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {isActive ? (
                  <Grid
                    style={{
                      background: 'rgba(155, 255, 155, 0.2)',
                      color: '#8AE98A',
                      padding: '7px 20px 7px 20px',
                      borderRadius: '30px'
                    }}
                  >
                    {status === 'trialing' ? TRIALING : ACTIVE}
                  </Grid>
                ) : (
                  <Alert severity="warning">{INACTIVE}</Alert>
                )}
              </Box>
            </Box>
            <CardField value={plan} />
            <Box
              style={{
                width: '100%',
                height: '2px',
                background: '#C9CAD9',
                marginTop: '20px'
              }}
            />
          </Box>

          <Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '390px'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '20px'
                }}
              >
                <Typography
                  style={{
                    color: '#131417',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 'bold'
                  }}
                >
                  {t('Last Billing Date')}:
                </Typography>
                <CardField value={formattedLastDate} />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '20px'
                }}
              >
                <Typography
                  style={{
                    color: '#131417',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 'bold'
                  }}
                >
                  {t('Next Billing Date')}:
                </Typography>
                <CardField value={formattedNextDate} />
              </Box>
            </Box>
            <Box
              style={{
                width: '100%',
                height: '2px',
                background: '#C9CAD9',
                marginTop: '20px'
              }}
            />
            <Box
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'space-between',
                width: '360px'
              }}
            >
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    color: '#131417',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 700
                  }}
                >
                  {t('Allowed Assesments')}:
                </Typography>
                <CardField value={fild_subscription_limit} />
              </Box>
              <Box>
                <Typography
                  style={{
                    color: '#131417',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 700
                  }}
                >
                  {t('Allowed Jobs')}:
                </Typography>
                <CardField value={fild_jobs_limit} />
              </Box>
            </Box>
          </Box>
        </Box>
        {!(company.subscription_id === 'free') && (
          <MButton
            title={t('Manage subscription')}
            MyClass="violetButton"
            click={manageSubscription}
            style={{ width: '250px' }}
          />
        )}
      </Card>
    );
  };

  if (isLoading)
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Loader />
      </Box>
    );

  if (!isLoading && !stripePromise) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Typography>Cannot initialize page, keys wasn't provided</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <Elements stripe={stripePromise}>
        <PaymentModal
          open={open}
          onClose={(value: boolean, stripe: any) => {
            if (value && activeData) {
              handleSubmitOnPlan(
                isYearly ? activeData.yearlyPlanId : activeData.monthlyPlanId,
                stripe
              );
            } else {
              setOpen(false);
            }
          }}
          sessionId={submittedSessionId}
          setSubscription={async () => {
            await getInitialData();
          }}
        />
        <PlansList />
      </Elements>
    </Box>
  );
};
export default SubscriptionPlanList;
