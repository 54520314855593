import { FC } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IFillter {
  options: string[] | any;
  activeOption?: any;
  setOption: (value: any) => any;
  style?: any;
  billing?: boolean;
  isCalendar?: boolean;
  isInvitation?: boolean;
}

const MFilter: FC<IFillter> = ({
  options,
  activeOption,
  setOption,
  style,
  billing,
  isCalendar,
  isInvitation
}) => {
  const useStyles = makeStyles({
    violetButton: {
      background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
      color: '#FFFFFF',
      borderRadius: '30px',
      minWidth: billing ? '98px' : '73px',
      height: '36px',
      fontFamily: 'Rubik',
      fontWeight: billing ? 700 : 400
    },
    violetButtonDisabled: {
      background: 'transparent',
      color: 'black',
      borderRadius: '30px',
      minWidth: billing ? '98px' : '73px',
      height: '36px',
      fontFamily: 'Rubik',
      fontWeight: billing ? 700 : 400
    }
  });
  const classes = useStyles();

  return (
    <Grid container style={style} direction="row">
      {options.map((option: any) => {
        const value = typeof option === 'string' ? option : option.value;

        return (
          <Grid key={option.value}>
            <Button
              className={
                activeOption === value
                  ? classes.violetButton
                  : classes.violetButtonDisabled
              }
              // variant={activeOption === value ? 'contained' : 'outlined'}
              onClick={() => setOption(value === activeOption ? '' : value)}
            >
              {!(isCalendar || isInvitation) ? option.value : option.label}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default MFilter;
