import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import {
  Grid,
  Link,
  TextField,
  Typography,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import { Description2 } from 'components/text/title';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { ROLE_CHOICES } from 'constants/constants';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { emailError, passwordError } from '../../../utils/helpError';
import { MIconButton } from '../../@material-extend';
import useLocales from '../../../hooks/useLocales';
import { RootState } from '../../../redux/store';
import { InitialValues } from './types';

const TextFieldStyle = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    background: '#FFFFFF',
    border: '1px solid #C9CAD9',
    borderRadius: '30px'
  }
}));

export default function RegisterForm({ color, linkedin_signup_url }: any) {
  const [showPassword, setShowPassword] = useState(false);
  const { register } = useAuth();
  const { t } = useLocales();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const invitation = new URLSearchParams(useLocation().search).get(
    'invitation'
  );
  const role = new URLSearchParams(useLocation().search).get('role');
  let email = new URLSearchParams(useLocation().search).get('email');
  if (email) {
    email = email.replace(/ /g, '+');
  }

  const asRecruiter = useMemo(() => !!email && !role, [email, role]);

  const requiresPassword =
    role === 'recruiter' ||
    role === 'recruiter_plus' ||
    role === 'admin' ||
    role === 'recruiter' ||
    role === 'hiring_manager' ||
    role === 'reviewer' ||
    role === 'designer';

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('Too Short!'))
      .max(50, t('Too Long!'))
      .required(t('First name required')),
    lastName: Yup.string()
      .min(2, t('Too Short!'))
      .max(50, t('Too Long!'))
      .required(t('Last name required')),
    email: Yup.string()
      .email(t('Email must be a valid email address'))
      .required(t('Email is required')),
    isAgree: Yup.boolean().oneOf([true], 'Please check the checkbox'),
    ...(invitation && role !== 'employee' && role !== 'candidate'
      ? { password: Yup.string().required(t('Password is required')) }
      : {})
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: email || '',
      isAgree: false,
      ...(invitation && requiresPassword ? { password: '' } : {})
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      if (values.isAgree) {
        try {
          await register({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            asRecruiter,
            role,
            invitation,
            password: values.password
          });
          enqueueSnackbar(t('Registration success'), {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        } catch (error: any) {
          if (isMountedRef.current) {
            setErrors({ afterSubmit: error.code || error.message });
            setSubmitting(false);
          }
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {linkedin_signup_url && (
            <>
              <Grid item width="100%">
                <Link href="/api/social-auth/login/linkedin-oauth2/">
                  <ButtonPrimary
                    onClick={() => {}}
                    fullWidth
                    style={{
                      height: '50px',
                      borderRadius: '30px',
                      background: `linear-gradient(92.25deg, ${color}90 -2.57%, ${color} 113.38%)`
                    }}
                  >
                    {t('Sign Up With Linkedin')}
                  </ButtonPrimary>
                </Link>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Description2 style={{ fontWeight: '900', fontSize: '24px' }}>
                  {t('OR')}
                </Description2>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Description2>{t('First name')}</Description2>
            <TextFieldStyle
              fullWidth
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <Description2>{t('Last name')}</Description2>
            <TextFieldStyle
              fullWidth
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid sx={{ my: 3 }}>
          <Description2>{t('Email address')}</Description2>
          <TextFieldStyle
            fullWidth
            autoComplete="email"
            disabled={asRecruiter}
            {...getFieldProps('email')}
            error={
              Boolean(touched.email && errors.email) ||
              emailError(errors.afterSubmit || '').error
            }
            helperText={
              (touched.email && errors.email) ||
              emailError(errors.afterSubmit || '').helperText
            }
          />
        </Grid>
        {invitation && requiresPassword && (
          <Grid>
            <Description2>{t('Password')}</Description2>
            <TextFieldStyle
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={
                Boolean(touched.password && errors.password) ||
                passwordError(errors.afterSubmit || '').error
              }
              helperText={
                (touched.password && errors.password) ||
                passwordError(errors.afterSubmit || '').helperText
              }
            />
          </Grid>
        )}

        <Grid container alignItems="start" justifyContent="flex-end">
          <FormControlLabel
            sx={{
              margin: '10px 0 20px'
            }}
            control={
              <Checkbox
                {...getFieldProps('isAgree')}
                sx={{
                  color: `${
                    formik.errors.isAgree ? 'rgb(255, 72, 66)' : '#11b08a'
                  }`
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                align="left"
                sx={{
                  color: formik.errors.isAgree ? 'rgb(255, 72, 66)' : '#A9AAB7',
                  mt: '10px'
                }}
              >
                {t('I give consent that the company may hold my profile in their database for a minimum of 4 weeks and a maximum of up to 1 year and I agree to ')}
                <a
                  style={{
                    color: formik.errors.isAgree
                      ? 'rgb(255, 72, 66)'
                      : '#A9AAB7'
                  }}
                  href={company?.general_terms}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms of Service ')}
                </a>
                and{' '}
                <a
                  style={{
                    color: formik.errors.isAgree
                      ? 'rgb(255, 72, 66)'
                      : '#A9AAB7'
                  }}
                  href={company?.privacy_statement}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Privacy Policy')}
                </a>
              </Typography>
            }
            checked={formik.values.isAgree}
            onChange={formik.handleChange}
            name="isAgree"
          />
          <ButtonPrimary
            type="submit"
            pending={isSubmitting}
            style={{
              minWidth: '130px',
              background: `linear-gradient(92.25deg, ${color}90 -2.57%, ${color} 113.38%)`
            }}
          >
            {invitation
              ? `${ROLE_CHOICES.find((rc) => rc.value === role)?.label} Sign Up`
              : t('Sign Up')}
          </ButtonPrimary>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
