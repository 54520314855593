import { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  addEvent,
  deleteEvent,
  editEvent,
  getEventsData,
  setApplicantsFilter
} from 'redux/slices/events';
import {
  getMessengerTemplate,
  saveTemplate,
  addTemplateCount
} from 'redux/slices/chat';
import moment from 'moment';
import {
  TextField,
  Button,
  Container,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  Typography,
  Paper,
  Tooltip,
  Zoom
} from '@material-ui/core';
import { Autocomplete, styled } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  AttachFile as AttachFileIcon,
  DescriptionOutlined as DescriptionIcon,
  CalendarMonth as CalendarMonthIcon,
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  AutoAwesomeMosaicOutlined as AutoAwesomeMosaicOutlinedIcon,
  Clear as ClearIcon
} from '@mui/icons-material';

import { getCandidateData } from 'requests/candidate';
import { GetEventById } from 'requests/events';
import useAuth from 'hooks/useAuth';
import DashboardNavbar from 'layouts/dashboard/navbar/DashboardNavbar';
import { CalendarEnum, eventTypes } from 'components/calendar';
import { CustomModal } from 'components/CustomModal';
import { OverviewSearch, OverviewSelect } from 'components/OverviewFilter';
import { DynamicInput } from 'components/DynamicForm';
import { PATH_DASHBOARD } from 'routes/paths';
import MFilter from 'components/new-designer/fillter/fillter';
import { getTranslatedList } from 'constants/constants';
import { FDateD } from 'utils/formatTime';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DateTimePicker from 'components/calendarComponent/calendarComponent';
import { RootState } from 'redux/store';
import ReactHtmlParser from 'react-html-parser';
import TimePicker from 'components/timePicker/timePicker';
import ReactQuill from 'react-quill';
import IconButton from '@material-ui/core/IconButton';
import { deleteDataByUrl, postDataByUrl, putDataByUrl } from 'requests';

interface Template {
  id: number;
  label: string;
  name: string;
  content: string;
  click_count: number;
  created_at: string;
  owner: number;
}

interface Upload {
  id?: number | null;
  url: any;
}

const IconStyle = { width: 22 };

const IconsWithHover = {
  ...IconStyle,
  color: '#C9CAD9',
  ':hover': { color: '#562C82' }
};

const ReactQuillBody = styled(ReactQuill)(() => ({
  '.ql-snow': {
    background: '#FFFFFF',
    border: 'none',
    width: '90%',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left'
  },
  '.ql-snow:first-of-type': {
    border: 'none',
    width: '100%',
    paddingRight: 0
  },
  '.ql-snow:last-of-type': {
    border: 'none',
    width: '100%'
  },
  '.ql-editor': { width: '85%' }
}));

const localizer = momentLocalizer(moment);

function Calendar({ jobDetails, candidateProfile }: any) {
  const { templates } = useSelector((state: RootState) => state.chat);
  const { id }: any = useParams();
  const location = useLocation();
  const [calendarEvent, setCalendarEvent] = useState('');
  const initialFormData: any = {
    title: '',
    candidate: candidateProfile ? id : '',
    description: '',
    vacancy: `${jobDetails?.id ?? ''}`,
    event_type: 0,
    start: new Date(),
    end: null,
    interviewers: [],
    type: calendarEvent ? CalendarEnum.EDIT : CalendarEnum.ADD,
    duration: new Date(new Date(new Date().setMinutes(0)).setHours(0)),
    uploads: []
  };

  const dispatch = useDispatch();
  const {
    response,
    filters: { query, year_month, periodicity, owner, limit }
  } = useSelector((state: any) => state.events);
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalCalendarPicker, setOpenModalCalendarPicker] = useState<
    string | null | any
  >(null);
  const [formData, setFormData] = useState(initialFormData);
  const [timeOutFun, setTimeoutFun] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  // const initialText = `${formData.description} \n \n `;
  const { PERIOD_TYPES_CALENDAR } = getTranslatedList();
  const [period, setPeriod] = useState<string>(PERIOD_TYPES_CALENDAR[2].value);
  const [templatesArray, setTemplatesArray] = useState<Template[]>([]);
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [filesToShow, setFilesToShow] = useState<Upload[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const calendarIcon = '/static/img/icons/calendarNewIcon.svg';

  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const formControlRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    const { target } = event;
    if (
      formControlRef.current &&
      !formControlRef.current.contains(target) &&
      !target.closest('.MuiAutocomplete-popper') &&
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(target)
    ) {
      setShowTemplates(false);
    }
  };

  useEffect(() => {
    const backendFiles = formData.uploads;
    setFilesToShow(backendFiles);
  }, [formData.id]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (candidateProfile) {
      setFormData({ ...formData, candidate: candidateProfile ? id : '' });
    }
  }, [id]);

  const openModalCalendar = (type: string) => {
    if (!isCandidate()) setOpenModalCalendarPicker(type);
  };

  useEffect(() => {
    if (!isCandidate() && user) {
      const aaa = {
        ...formData,
        interviewers: [{ label: user.name, id: user.id, name: user.name }]
      };
      setFormData(aaa);
    }
  }, [user]);

  const getEvent = async () => {
    if (location && location.search.includes('event=')) {
      const eventparam = location.search.split('event=');
      if (eventparam[eventparam.length - 1]) {
        setCalendarEvent(eventparam[eventparam.length - 1]);
        const event = await GetEventById(eventparam[eventparam.length - 1]);
        const startDate: any = new Date(event.start);
        const endDate: any = new Date(event.end);
        const timeDifference: number = endDate - startDate;
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        const duration = new Date(
          new Date(new Date().setHours(hours)).setMinutes(minutes)
        );

        setFormData({
          ...event,
          type: CalendarEnum.EDIT,
          duration,
          interviewers: event?.interviewers?.map((e: any) => ({
            label: `${e.first_name} ${e.last_name}`,
            name: `${e.first_name} ${e.last_name}`,
            id: e.id
          }))
        });
        if (!openModalCalendarPicker)
          setOpenModal(!!eventparam[eventparam.length - 1]);
      }
    }
  };

  useEffect(() => {
    getEvent();
  }, [location, calendarEvent]);

  const removeEvent = () => {
    setOpenModal(true);
    setFormData({ ...formData, type: CalendarEnum.DELETE });
  };

  const isCandidate = (): boolean => user.role === CalendarEnum.CANDIDATE;

  const handleEventClick = (event: any) => {
    navigate(`${PATH_DASHBOARD.calendar.root}?event=${event.id}`);
    setCalendarEvent(`${event.id}`);
  };

 const handleSearch = async () => {
   const val = setTimeout(async () => {
     const currentdate = new Date();

     const currentyear = currentdate.getFullYear();
     const currentmonth = String(currentdate.getMonth() + 1).padStart(2, '0');

     const currentFormattedDate = `${currentyear}-${currentmonth}`;
     const periodicityFilter =
       currentFormattedDate !== year_month ? '' : periodicity;
     dispatch(
       getEventsData(
         query,
         year_month,
         periodicityFilter,
         user?.role !== CalendarEnum.ADMIN ? '' : owner,
         limit
       )
     );
   }, 1000);
   setTimeoutFun(val);
 };

 useEffect(() => {
   if (timeOutFun) {
     clearTimeout(timeOutFun);
   }
   handleSearch();
 }, [query, year_month, periodicity, owner]);

 const handleChange = (event: any, type?: string) => {
   const name: any = type || event?.target?.name;
   const value: any = type ? event : event?.target?.value;

   setFormData({
     ...formData,
     [name]: name === CalendarEnum.END ? value || '' : value
   });
 };

 const schedule = () => {
   setFormData({
     ...formData,
     type: CalendarEnum.ADD
   });

   setOpenModal(true);
 };

 useEffect(() => {
   if (id) {
     const getCandidate = async () => {
       const candidate = await getCandidateData(id);
       setFormData({
         ...formData,
         candidate
       });
     };

     if (
       (!jobDetails && !calendarEvent && !isCandidate()) ||
       candidateProfile
     ) {
       getCandidate();
     }
   }
 }, [openModal, id]);

 useEffect(() => {
   const getJob = async () => {
     setFormData({
       ...formData,
       vacancy: jobDetails
     });
   };

   !!jobDetails && getJob();
 }, [jobDetails, openModal]);

 const handleInitialFormData = (): void => {
   if (calendarEvent && !openModalCalendarPicker) {
     navigate(PATH_DASHBOARD.calendar.root);
   }
   if (!openModalCalendarPicker) {
     setFormData(initialFormData);
     setErrorMessage('');
     setOpenModal(false);
   }
   setOpenModalCalendarPicker(null);
   if (!formData?.end) {
     setFormData({ ...formData, end: formData?.start });
   }
   setSelectedFiles([]);
 };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { type, duration, uploads, ...payload }: any = formData;
    payload.candidate = candidateProfile
      ? Number(id)
      : Number(formData?.candidate?.id) || Number(formData?.candidate);

    if (
      !payload?.title ||
      !payload?.candidate ||
      (!payload?.event_type && payload?.event_type !== 0) ||
      !payload?.start
    ) {
      setErrorMessage(t('Please fill required fields'));
      return;
    }

    const endDate = new Date(
      new Date(payload.start).getTime() +
        duration.getHours() * 60 * 60 * 1000 +
        duration.getMinutes() * 60 * 1000
    );
    payload.end = endDate.toISOString();
    payload.start = new Date(formData.start).toISOString();

    if (duration.getHours() == 0 && duration.getMinutes() == 0) {
      setErrorMessage(t('Please add duration!'));
      return;
    }
    payload.interviewers = [
      ...new Set(payload.interviewers.map((e: any) => e.id))
    ];
    if (payload.vacancy) {
      payload.vacancy = `${payload?.vacancy?.id}`;
    } else {
      delete payload.vacancy;
    }

    if (type != CalendarEnum.DELETE && new Date(formData?.start) < new Date()) {
      setErrorMessage(t('Start date should be more than now!'));
      return;
    }

    const formDataObj = new FormData();

    if (type === CalendarEnum.EDIT) {
      for (const key in payload) {
        if (
          Object.prototype.hasOwnProperty.call(payload, key) &&
          key !== 'creator' &&
          key !== 'candidate' &&
          key !== 'interviewers'
        ) {
          formDataObj.append(key, payload[key]);
        }
      }
    } else {
      for (const key in payload) {
        if (
          Object.prototype.hasOwnProperty.call(payload, key) &&
          key !== 'interviewers'
        ) {
          formDataObj.append(key, payload[key]);
        }
      }
    }
    if (type === CalendarEnum.ADD) {
      // Append files to formDataObj if they exist
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          formDataObj.append('uploads', file.url);
        });
      }
    }

    if (type === CalendarEnum.EDIT) {
      if (uploads.length < 3) {
        const updateFormData = new FormData();
        if (selectedFiles.length > 0) {
          selectedFiles.slice(0, 3 - uploads.length).forEach((filteredFile) => {
            updateFormData.append('uploaded_file', filteredFile.url);
            // Append the event ID to the form data
            updateFormData.append('event', payload.id);
            const editFiles = async () => {
              try {
                await postDataByUrl('/api/calendars/files/', updateFormData);
                // Optionally update state or perform further actions
              } catch (error) {
                console.error('Error uploading files:', error);
                // Handle error
              }
            };
            editFiles();
          });
        }
      }
    }

    payload.interviewers.forEach((interviewerId: number) => {
      formDataObj.append('interviewers', String(interviewerId));
    });

    if (type === CalendarEnum.EDIT) {
      formDataObj.append('creator', String(user.id));
      formDataObj.append(
        'candidate',
        String(Number(formData?.candidate?.id) || Number(formData?.candidate))
      );
    }

    if (type === CalendarEnum.ADD) {
      formDataObj.append('creator', String(user.id));
    }

    const actionPayload =
      selectedFiles.length > 0
        ? formDataObj
        : {
            ...payload,
            creator: user.id,
            candidate:
              Number(formData?.candidate?.id) || Number(formData?.candidate)
          };

    switch (type) {
      case CalendarEnum.ADD:
        dispatch(addEvent(actionPayload));
        break;
      case CalendarEnum.EDIT:
        dispatch(editEvent(payload?.id, actionPayload));
        break;
      case CalendarEnum.DELETE:
        dispatch(deleteEvent(payload?.id));
        break;
      default:
    }

    handleInitialFormData();
    setSelectedFiles([]);
  };

  const handleSetValue = useCallback(
    (value: any) => {
      dispatch(setApplicantsFilter('query', value));
    },
    [dispatch]
  );

  const handleAutocompleteChange = (event: any, newValue: Template | null) => {
    if (newValue) {
      const selectedTemplate = templatesArray.find(
        (template) =>
          template.content === newValue.content ||
          template.name === newValue.name
      );
      if (selectedTemplate) {
        addTemplateCount(selectedTemplate.id);

        setFormData((prevFormData: any) => ({
          ...prevFormData,
          description: `${prevFormData.description || ''}${newValue.content}`
        }));
      }
    }
  };

  useEffect(() => {
    getMessengerTemplate('');
  }, []);

  useEffect(() => {
    setTemplatesArray(templates);
  }, [templates]);

  const saveToTemplates = (valueMessage: any) => {
    if (
      !templates?.some(
        (t) => t.content.toLowerCase() === valueMessage.toLowerCase()
      ) &&
      !templatesArray?.some(
        (t) => t.content.toLowerCase() === valueMessage.toLowerCase()
      )
    ) {
      saveTemplate({
        content: valueMessage
      });
      getMessengerTemplate('');
      setTemplatesArray(templates);
    }
  };

  const validateFiles = (files: FileList) => {
    const maxSize = 5 * 1024 * 1024; // Max size of each file
    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    const filesArray = Array.from(files);
    const validFiles = [];
    const invalidFileSizeMessage = [];
    const invalidFileTypeMessage = [];

    for (const file of filesArray) {
      if (file.size > maxSize) {
        invalidFileSizeMessage.push(file.name);
      } else if (!validTypes.includes(file.type)) {
        invalidFileTypeMessage.push(file.name);
      } else {
        validFiles.push(file);
      }
    }

    if (invalidFileSizeMessage.length > 0) {
      alert(
        `The following files are too large (max size is 5MB): ${invalidFileSizeMessage.join(
          ', '
        )}`
      );
    }
    if (invalidFileTypeMessage.length > 0) {
      alert(
        `Invalid file types detected. Only PDF, DOC, and DOCX are allowed: ${invalidFileTypeMessage.join(
          ', '
        )}`
      );
    }

    return validFiles;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const newValidFiles = validateFiles(event.target.files);
      const totalFiles = selectedFiles.length + newValidFiles.length;

      if (totalFiles > 3) {
        alert(`You can only upload a maximum of 3 files.`);
      }
      const filesToAdd =
        totalFiles > 3
          ? newValidFiles.slice(0, 3 - selectedFiles.length)
          : newValidFiles;

      const newFiles = filesToAdd.map((file) => ({
        id: null,
        url: file
      }));

      setSelectedFiles([...selectedFiles, ...newFiles]);
      setFilesToShow([...filesToShow, ...newFiles]);
    }
    event.target.value = '';
  };

  const handleDeleteFile = async (fileToDelete: any) => {
    if (fileToDelete.id === null) {
      // Handle the file in selectedFiles
      setSelectedFiles(
        selectedFiles.filter(
          (file) =>
            file.url.name !== fileToDelete.url.name ||
            file.url.size !== fileToDelete.url.size ||
            file.url.lastModified !== fileToDelete.url.lastModified
        )
      );
      setFilesToShow(
        filesToShow.filter(
          (file) =>
            !(
              file.url instanceof File &&
              file.url.name === fileToDelete.url.name &&
              file.url.size === fileToDelete.url.size &&
              file.url.lastModified === fileToDelete.url.lastModified
            )
        )
      );
    } else {
      // Handle the file in filesToShow
      try {
        await deleteDataByUrl(`/api/calendars/files/${fileToDelete.id}/`);
        setFilesToShow(
          filesToShow.filter((file) => file.id !== fileToDelete.id)
        );
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          uploads: prevFormData.uploads.filter(
            (upload: any) => upload.id !== fileToDelete.id
          )
        }));
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  const CustomToolbar = (props: any) => {
    const { date, onNavigate } = props;

    const currentyear = date.getFullYear();

    const setOption = (value: any) => {
      dispatch(setApplicantsFilter('periodicity', value));
      setPeriod(value);
    };

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '110px',
          maxWidth: '74%'
        }}
      >
        <MFilter
          isCalendar
          style={{
            marginTop: '24px',
            marginBottom: '34px',
            display: 'flex'
          }}
          billing={true}
          activeOption={period}
          options={PERIOD_TYPES_CALENDAR}
          setOption={setOption}
        />
        <div
          style={{
            width: '100%'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={() => {
                onNavigate('PREV');
              }}
            >
              <ArrowBackIosNewIcon style={{ margin: '0 -10px 0 -10px' }} />
            </Button>
            <h2>
              {moment(date).format('MMMM')}&nbsp;
              {currentyear}
            </h2>
            <Button
              onClick={() => {
                onNavigate('NEXT');
              }}
            >
              <ArrowForwardIosIcon />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {jobDetails && !isCandidate() && (
        <Button
          style={{
            background:
              'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
            color: '#fff',
            fontSize: ' 13px',
            borderRadius: '5px',
            marginTop: '2px',
            margin: '0 30px'
          }}
          color="primary"
          onClick={() => schedule()}
        >
          <CalendarMonthIcon /> {t('Schedule')}
        </Button>
      )}
      {!jobDetails && <DashboardNavbar title={t('Calendar')} />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 30px -6px'
        }}
      >
        {user?.role === CalendarEnum.ADMIN && !jobDetails && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  '& span': {
                    width: '21px'
                  },
                  '& img': {
                    width: '21px'
                  }
                }}
                defaultChecked={owner === 'all'}
                icon={<img src="/static/img/icons/checkbox-calendar.svg" />}
                checkedIcon={<img src="/static/img/icons/activeCeckboxEvent.svg" />}
              />
            }
            label={t('Show all events')}
            onChange={(_: React.SyntheticEvent, checked: boolean) => {
              dispatch(setApplicantsFilter('owner', checked ? 'all' : 'me'));
            }}
          />
        )}
        <div
          style={{
            display: 'flex',
            columnGap: '66px',
            alignItems: 'flex-start'
          }}
        >
          {!openModal && !jobDetails && (
            <OverviewSearch
              eventSearch
              placeholder={t('Search by Name')}
              setValue={handleSetValue}
              value={query}
              timeout={0}
            />
          )}
          {!isCandidate() && !jobDetails && (
            <button
              style={{
                width: '35px',
                height: '35px',
                background: ' #562C82',
                color: '#fff',
                fontSize: ' 13px',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                cursor: 'pointer',
                marginTop: '5px'
              }}
              color="primary"
              onClick={() => schedule()}
            >
              <img
                src="/static/img/icons/calendar.svg"
                width="23px"
                height="23px"
                alt="calendar"
              />
            </button>
          )}
        </div>
      </div>
      <Grid
        sx={{
          ...(!jobDetails
            ? {
                borderRadius: '5px',
                background: '#FFF',
                boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)',
                maxHeight: '1000px',
                height: '100%',
                padding: candidateProfile ? '0' : '17px',
                margin: '0 30px',
                '& .rbc-time-content::-webkit-scrollbar': {
                  width: '4px',
                  border: 0
                },

                '& .rbc-time-content::-webkit-scrollbar-track': {
                  backgroundColor: '#C9CAD9'
                },

                '& .rbc-time-content::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgb(101, 55, 149)',
                  borderRadius: '4px',
                  outline: '1px solid rgb(101, 55, 149)'
                },

                '& .rbc-row.rbc-month-header': {
                  background: '#E9EBF7',
                  padding: '14px 0',
                  borderRadius: '12px 12px 0px 0px'
                },
                '& .rbc-month-view': {
                  border: '1px solid #E9EBF7',
                  borderRadius: '12px 12px 0px 0px'
                },
                '& .rbc-header': {
                  border: 'none',
                  color: '#562C82'
                },
                '& .rbc-header + .rbc-header': {
                  border: 'none'
                },
                '& .rbc-row-segment': {
                  width: '90%',
                  padding: '2px 10px'
                },
                '& .rbc-off-range-bg': {
                  background: 'none !important'
                }
              }
            : {})
        }}
      >
        <CustomModal
          open={openModal}
          onClose={() => handleInitialFormData()}
          style={
            openModalCalendarPicker
              ? {
                  background:
                    'linear-gradient(130deg, #F2F4FD -0.3%, #E0E2F1 101.32%)',
                  borderRadius: '9px',
                  padding: '24px 28px'
                }
              : {
                  padding: '42px 35px 29px 35px'
                }
          }
        >
          {!openModalCalendarPicker ? (
            <>
              <Button
                onClick={() => handleInitialFormData()}
                style={{
                  cursor: 'pointer',
                  top: '40px',
                  width: '20px',
                  height: '0px',
                  position: 'absolute',
                  right: '10px',
                  backgroundColor: 'transparent'
                }}
              >
                <img src="/static/img/icons/remove.svg" />
              </Button>
              <h2>{t('Schedule event')}</h2>
              {formData?.type === CalendarEnum.DELETE ? (
                <>
                  <div>{t('Are you sure wanna remove this event?')}</div>
                  <Grid
                    container
                    style={{
                      justifyContent: 'space-evenly',
                      marginTop: '20px'
                    }}
                  >
                    <Button onClick={handleSubmit}>{t('Remove')}</Button>
                    <Button
                      onClick={() => {
                        setOpenModal(false);
                        setFormData(initialFormData);
                      }}
                    >
                      {t('Cancel')}
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Container>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        {formData?.type === CalendarEnum.EDIT &&
                        !isCandidate() ? (
                          <Button
                            onClick={removeEvent}
                            style={{
                              float: 'right',
                              marginLeft: 'auto'
                            }}
                          >
                            {t('Remove')}
                          </Button>
                        ) : (
                          <></>
                        )}
                        <Typography
                          style={{
                            fontFamily: 'Rubik',
                            color: '#666666',
                            marginTop: '6px',
                            fontSize: '14px'
                          }}
                        >
                          {t('Title')}
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          disabled={isCandidate()}
                          required
                          sx={{
                            marginTop: '5px'
                          }}
                        />
                      </Grid>
                      <div
                        style={{
                          display: 'flex',
                          columnGap: '16px',
                          marginTop: '20px',
                          maxWidth: isCandidate() ? '100%' : '700px',
                          flexWrap: 'wrap'
                        }}
                      >
                        {(!id || jobDetails || calendarEvent) &&
                        !isCandidate() ? (
                          <div
                            style={{
                              width: '48.5%'
                            }}
                          >
                            <DynamicInput
                              isCandidate={isCandidate()}
                              withInitalData
                              textAlign="start"
                              value={formData?.candidate}
                              type="select-candidate"
                              options={[]}
                              onChange={(_: any, e: any) => {
                                setFormData({
                                  ...formData,
                                  candidate: e
                                });
                              }}
                              label={t('Candidate *')}
                              multiple={false}
                              onInputChange={() =>
                                setFormData({ ...formData, candidate: '' })
                              }
                              fontSize="0.95rem"
                              disabled={isCandidate() || candidateProfile}
                              required
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          style={{
                            width: isCandidate() ? '100%' : '48.5%'
                          }}
                        >
                          <DynamicInput
                            withInitalData
                            textAlign="start"
                            value={formData?.vacancy}
                            type="select-job"
                            options={[]}
                            onChange={(r: any, e: any) => {
                              setFormData({
                                ...formData,
                                vacancy: e
                              });
                            }}
                            label={t('Job')}
                            multiple={false}
                            onInputChange={() =>
                              setFormData({ ...formData, vacancy: '' })
                            }
                            fontSize="0.95rem"
                            disabled={
                              isCandidate() || (jobDetails && !candidateProfile)
                            }
                          />
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            columnGap: '16px',
                            position: 'relative',
                            alignItems: 'center'
                          }}
                        >
                          <Grid
                            sx={{
                              width: '290px',
                              '& div': {
                                width: '100%'
                              },
                              '& input': {
                                width: '1080px'
                              }
                            }}
                          >
                            <h5
                              style={{
                                color: '#666666',
                                fontSize: '0.95rem',
                                fontWeight: '400',
                                textAlign: 'start',
                                paddingBottom: '4px'
                              }}
                            >
                              {t('Date')}
                            </h5>
                            <Box
                              style={{ position: 'relative', display: 'flex' }}
                              onClick={() =>
                                openModalCalendar(CalendarEnum.START)
                              }
                            >
                              <input
                                disabled={isCandidate()}
                                onChange={(e) =>
                                  handleChange(e, CalendarEnum.START)
                                }
                                style={{
                                  width: '322px',
                                  padding: '20px 17px 17px 20px',
                                  borderRadius: '5px',
                                  border: '1px solid #C9CAD9',
                                  cursor: 'pointer',
                                  fontSize: '14px',
                                  textAlign: 'initial'
                                }}
                                value={FDateD(formData?.start)}
                              />
                              <img
                                src={calendarIcon}
                                alt={calendarIcon}
                                style={{
                                  position: 'absolute',
                                  right: '20px',
                                  bottom: '15px'
                                }}
                              />
                            </Box>
                          </Grid>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'cneter',
                              columnGap: '25px'
                            }}
                          >
                            <TimePicker
                              formData={formData}
                              setFormData={setFormData}
                              field="start"
                              isCandidate={isCandidate()}
                            />
                            <TimePicker
                              formData={formData}
                              setFormData={setFormData}
                              field="duration"
                              fromDuration={true}
                              isCandidate={isCandidate()}
                            />
                          </Box>
                        </div>
                      </LocalizationProvider>
                      <br />
                      <p
                        style={{
                          textAlign: 'start',
                          fontSize: '0.95rem',
                          color: 'rgb(102, 102, 102)'
                        }}
                      >
                        {t('Event type')}
                      </p>
                      <OverviewSelect
                        width="100%"
                        title=""
                        activeOption={
                          eventTypes[formData?.event_type || 0]?.id || 0
                        }
                        disabled={isCandidate()}
                        options={eventTypes}
                        setOption={(value: any) =>
                          handleChange(Number(value), 'event_type')
                        }
                        filterSx={{
                          width: '100%',
                          maxWidth: '700px'
                        }}
                      />
                      <div
                        style={{
                          maxWidth: '700px'
                        }}
                      >
                        <Grid
                          sx={{
                            '& p': {
                              textAlign: 'start'
                            }
                          }}
                        >
                          <DynamicInput
                            eventInterview={true}
                            isCandidate={isCandidate()}
                            withInitalData
                            textAlign="start"
                            value={
                              !isCandidate()
                                ? formData.interviewers?.map((e: any) => ({
                                    label: `${e?.label}`,
                                    id: e?.id,
                                    ...e
                                  })) || []
                                : []
                            }
                            type="select-recruiter"
                            options={formData.interviewers?.map((e: any) => ({
                              label: `${e?.first_name} ${e?.last_name}`,
                              id: e?.id,
                              ...e
                            }))}
                            onChange={(r: any, e: any) => {
                              setFormData({
                                ...formData,
                                interviewers: [...e]
                              });
                            }}
                            label={t('Interviewers')}
                            multiple={true}
                            onInputChange={() =>
                              setFormData({ ...formData, interviewers: [] })
                            }
                            fontSize="0.95rem"
                            disabled={isCandidate()}
                          />
                        </Grid>
                      </div>
                      <p
                        style={{
                          textAlign: 'start',
                          fontSize: '0.95rem',
                          color: 'rgb(102, 102, 102)',
                          margin: '15px 0px 6px'
                        }}
                      >
                        {t('Description')}
                      </p>

                      <Grid
                        width="100%"
                        maxWidth="611px"
                        sx={{
                          position: 'relative',
                          border: '1px solid #C9CAD9',
                          borderRadius: '8px',
                          display: 'flex',
                          alignItems: 'flex-end',
                          justifyContent: 'space-between',
                          padding: '2px'
                        }}
                      >
                        <ReactQuillBody
                          placeholder={t('Description')}
                          value={formData.description}
                          onChange={(content) =>
                            handleChange(content, 'description')
                          }
                          modules={{
                            toolbar: [
                              [{ font: [] }],
                              [{ size: ['small', false, 'large', 'huge'] }],
                              ['bold', 'italic', 'underline'],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              [{ color: [] }, { background: [] }],
                              ['clean']
                            ]
                          }}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            right: '15px',
                            bottom: '10%',
                            width: '66px',
                            height: '66px',

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px'
                          }}
                        >
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept=".pdf, .doc, .docx"
                            onChange={handleFileUpload}
                            multiple
                            max={3}
                            style={{ display: 'none' }}
                          />
                          <Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              position="relative"
                            >
                              <Box
                                display="flex"
                                width="100%"
                                justifyContent="center"
                              >
                                {filesToShow.length < 3 && (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    title={t('Attach File')}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        if (fileInputRef.current) {
                                          fileInputRef.current.click();
                                        }
                                      }}
                                      sx={{ padding: 0, paddingRight: '5px' }}
                                    >
                                      <AttachFileIcon sx={IconsWithHover} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  title={t('Insert Template')}
                                >
                                  <IconButton
                                    ref={toggleButtonRef}
                                    onClick={() => {
                                      setShowTemplates(!showTemplates);
                                    }}
                                    sx={{ padding: 0 }}
                                  >
                                    <AutoAwesomeMosaicOutlinedIcon
                                      sx={{
                                        fontSize: '22px',
                                        color: '#562C82'
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Box display="flex" mt="5px">
                                {filesToShow?.map((file) => (
                                  <Tooltip
                                    key={file.id}
                                    arrow
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    title=""
                                  >
                                    <Box
                                      width={10}
                                      height={10}
                                      mb="2px"
                                      mr="10px"
                                      sx={{
                                        ':hover': {
                                          '& #file-icon': {
                                            display: 'none'
                                          },
                                          '& #clear': {
                                            display: 'flex',
                                            justifyContent: 'start'
                                          },
                                          '& #clear-icon': {
                                            display: 'flex'
                                          }
                                        }
                                      }}
                                    >
                                      <DescriptionIcon
                                        id="file-icon"
                                        sx={{ ...IconStyle, color: '#C9CAD9' }}
                                      />

                                      <IconButton
                                        id="clear"
                                        onClick={() => handleDeleteFile(file)}
                                        sx={{
                                          position: 'absolute',
                                          display: 'none',
                                          padding: 0,
                                          ':hover': {
                                            backgroundColor: 'transparent'
                                          }
                                        }}
                                        style={{
                                          overflow: 'hidden'
                                        }}
                                      >
                                        <ClearIcon
                                          id="clear-icon"
                                          sx={{
                                            ...IconStyle,
                                            color: 'red',
                                            display: 'none'
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Tooltip>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {showTemplates && (
                          <FormControl
                            ref={formControlRef}
                            sx={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-6px',
                              width: '620px'
                            }}
                          >
                            <Autocomplete
                              options={templatesArray}
                              open
                              getOptionLabel={(option) =>
                                option.label.replace(/<[^>]*>?/gm, '')
                              }
                              onChange={handleAutocompleteChange}
                              filterOptions={(options, { inputValue }) => {
                                if (!inputValue) {
                                  return options.slice(0, 10);
                                }
                                return options.filter((option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                                );
                              }}
                              PaperComponent={(props) => (
                                <Paper
                                  style={{
                                    background: 'white',
                                    fontSize: '14px',
                                    boxSizing: 'border-box',
                                    border: '1px solid #C9CAD9',
                                    borderBottom: 0,
                                    borderRadius: 0,
                                    boxShadow: 'none'
                                  }}
                                  {...props}
                                />
                              )}
                              sx={{
                                padding: 0,
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: 'white',
                                  borderRadius: 0,
                                  boxShadow:
                                    '0 0 2px 0 rgba(145, 158, 171, 0.24), 0 20px 40px -4px rgba(145, 158, 171, 0.24)'
                                },

                                '& .MuiAutocomplete-endAdornment': {
                                  display: 'none'
                                },
                                '& .MuiTextField-root': {
                                  borderColor: 'transparent'
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Search Templates"
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {ReactHtmlParser(option.label)}
                                </li>
                              )}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      {errorMessage ? (
                        <div
                          style={{
                            color: 'red',
                            textAlign: 'initial'
                          }}
                        >
                          {errorMessage}
                        </div>
                      ) : (
                        <></>
                      )}
                      {!isCandidate() ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Button
                            style={{
                              marginTop: '30px',
                              background:
                                'linear-gradient(92deg, #9B5FDA -2.57%, #562C82 113.38%)',
                              padding: '13px 40px',
                              borderRadius: '30px',
                              fontWeight: 'bold',
                              color: '#ffffff'
                            }}
                            type="submit"
                            variant="contained"
                          >
                            {t(`${formData?.type} Event`)}
                          </Button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </form>
                  </Container>
                </>
              )}
            </>
          ) : (
            <DateTimePicker
              formDataDate={formData[openModalCalendarPicker]}
              onChange={(value: Date) => {
                setFormData({
                  ...formData,
                  [openModalCalendarPicker]: value
                });
              }}
            />
          )}
        </CustomModal>
        {!jobDetails && (
          <BigCalendar
            localizer={localizer}
            events={
              (response?.results || response)?.map((event: any) => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end || event.start)
              })) || []
            }
            style={{
              height: '100%',
              background: '#fff',
              borderRadius: '5px'
            }}
            onSelectEvent={handleEventClick}
            views={['month', 'week', 'day']}
            popup
            eventPropGetter={(event) => {
              const typeColors: {
                [key: number]: { backgroundColor: string; textColor: string };
              } = {
                0: { backgroundColor: '#FAF6EB', textColor: '#D19C36' },
                1: { backgroundColor: '#FAEBF7', textColor: '#D1368A' },
                2: { backgroundColor: '#EBF5FA', textColor: '#3699D1' }
              };

              const eventType = event.event_type as number;

              if (eventType in typeColors) {
                const colors = typeColors[eventType];

                event.backgroundColor = colors.backgroundColor;
                event.textColor = colors.textColor;
              } else {
                event.backgroundColor = 'gray';
                event.textColor = 'black';
              }

              return {
                style: {
                  backgroundColor: event.backgroundColor,
                  color: event.textColor
                }
              };
            }}
            toolbar
            components={{
              toolbar: CustomToolbar
            }}
            onNavigate={(newDate: Date) => {
              const year: number = newDate.getFullYear();
              const month: string = String(newDate.getMonth() + 1).padStart(
                2,
                '0'
              );
              const formattedDate = `${year}-${month}`;
              dispatch(setApplicantsFilter('year_month', formattedDate));
            }}
          />
        )}
      </Grid>
    </>
  );
}

export default Calendar;
