import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import HeadingContainer from 'components/containers/HeadingContainer';
import ListContainer from 'components/containers/ListContainer';
import PaginationContainer from 'components/containers/PaginationContainer';
import { DynamicRadio } from 'components/DynamicForm';
import { ROLE_CHOICES } from 'constants/constants';
import useAuth from 'hooks/useAuth';
import useLocales from 'hooks/useLocales';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvitation,
  getInvitationsData,
  removeInvitation,
  setInvitationsFilter
} from 'redux/slices/invitations';
import { RootState } from 'redux/store';
import { ID } from 'requests';
import { resendInvitation } from 'requests/invitations';
import { InvitationType } from 'types/Invitation';
import { Icon } from '@iconify/react';
import MFilter from 'components/new-designer/fillter/fillter';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteDialog } from 'components/Dialog';

import closeIcon from '@iconify/icons-eva/close-fill';
import MButton from '../../../components/new-designer/button/MButton';

type InvitationFields = {
  allowedRoles: {
    value: string;
    label: string;
  }[];
  onSubmit: (values: InvitationType) => void;
};

const AddInvitationForm = ({ allowedRoles, onSubmit }: InvitationFields) => {
  const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    role: 'candidate',
    recruiter_permission: ''
  };
  const [values, setValues] = useState(initialState);
  const { isAdmin } = useAuth();

  const { t } = useLocales();
  const setNewValues = (field: string, value: any) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSubmit = () => {
    if (values.email && values.role) {
      onSubmit(values);
      setValues(initialState);
    }
  };
  useEffect(() => {
    if (values.role === 'recruiter') {
      setValues({
        ...values,
        recruiter_permission: 'view_all'
      });
    } else {
      setValues({
        ...values,
        recruiter_permission: ''
      });
    }
  }, [values.role]);

  const useStyles = makeStyles(() => ({
    root: {
      borderRadius: '30px'
    }
  }));

  const classes = useStyles();
  return (
    <Card
      sx={{
        p: 2,
        mt: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        boxShadow: 'none',
        width: '90vw',
        maxWidth: 500
      }}
    >
      <Typography
        style={{
          textAlign: 'start',
          width: '100%',
          color: '#666666',
          fontFamily: 'Rubik'
        }}
      >
        {t('First Name')}
      </Typography>
      <TextField
        InputProps={{ classes, disableUnderline: true }}
        sx={{ mb: 2 }}
        fullWidth
        value={values.first_name}
        onChange={(e) => setNewValues('first_name', e.target.value)}
      />
      <Typography
        style={{
          textAlign: 'start',
          width: '100%',
          color: '#666666',
          fontFamily: 'Rubik'
        }}
      >
        {t('Last Name')}
      </Typography>

      <TextField
        InputProps={{ classes, disableUnderline: true }}
        sx={{ mb: 2 }}
        fullWidth
        value={values.last_name}
        onChange={(e) => setNewValues('last_name', e.target.value)}
      />
      <Typography
        style={{
          textAlign: 'start',
          width: '100%',
          color: '#666666',
          fontFamily: 'Rubik'
        }}
      >
        {t('Email')}
      </Typography>

      <TextField
        InputProps={{ classes, disableUnderline: true }}
        sx={{ mb: 2 }}
        fullWidth
        type="email"
        value={values.email}
        onChange={(e) => setNewValues('email', e.target.value)}
      />
      <DynamicRadio
        style={{ fontFamily: 'Rubik' }}
        label={t('Role')}
        value={values.role}
        row
        options={allowedRoles.map((role) => ({
          value: role.value,
          label: t(role.label)
        }))}
        onChange={(e: any) => setNewValues('role', e.target.value)}
      />
      {values.role === 'recruiter' && (
        <DynamicRadio
          style={{ fontFamily: 'Rubik' }}
          value={values.recruiter_permission}
          row
          options={[
            {
              value: 'view_all',
              label: t('Assign rights to view ALL jobs and ALL candidates')
            },
            {
              value: 'view_if_owner',
              label: t('Assign rights to view personal jobs and candidates only')
            },
            {
              value: 'view_only_vacancies',
              label: t('Assign rights to view ALL jobs but not ALL candidates')
            }
          ]}
          disabled={!isAdmin}
          onChange={(e: any) =>
            setNewValues('recruiter_permission', e.target.value)
          }
        />
      )}
      <MButton
        title={t('Send Invitation')}
        MyClass="violetButton"
        click={handleSubmit}
      />
    </Card>
  );
};

const InvitationCard = ({ item, loading, onDelete, onResend }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useLocales();
  const { first_name, last_name, email, role, invited_by, sent_on } = item;
  const name =
    !first_name && !last_name
      ? t("Name isn't indicated")
      : `${first_name || ''} ${last_name || ''}`;
  const styleCard = {
    background:
      'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: '20px',
    padding: '30px 30px 40px 30px',
    width: '700px',
    height: '201px'
  };

  return (
    <Card style={styleCard}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid xs={12} container>
            <Grid xs={9}>
              <Typography
                color="#131417"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '20px',
                  fontWeight: 700
                }}
              >
                <span
                  style={{
                    textTransform: 'capitalize',
                    fontFamily: 'Rubik',
                    fontWeight: 700
                  }}
                >
                  {ROLE_CHOICES.find((roleChoice) => roleChoice.value === role)
                    ?.label || 'Role not found'}
                </span>
                : {name}
              </Typography>
              <Typography variant="body1">{email}</Typography>
            </Grid>
            <Grid xs={3}>
              <Grid
                style={{
                  background: item.accepted
                    ? 'rgba(155, 255, 155, 0.2)'
                    : 'rgba(240, 180, 89, 0.20)',
                  color: item.accepted ? '#8AE98A' : '#F0B459',
                  borderRadius: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '36px',
                  alignItems: 'center',
                  width: item.accepted ? '108px !important' : '161px !important'
                }}
              >
                {item.accepted ? t('Accepted') : t('Not accepted yet')}
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid
            xs={12}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexBasis="30%"
          >
            <Grid xs={6}>
              <Typography style={{ fontFamily: 'Rubik', fontWeight: 700 }}>
                {t('Invited by')}: {invited_by}
              </Typography>
              <Typography variant="caption">
                {new Date(sent_on).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid
              xs={6}
              display="flex"
              flexDirection="row"
              flexBasis="0%"
              columnGap="14px"
            >
              <Button
                style={{
                  backgroundColor: 'transparent',
                  color: '#562C82',
                  fontFamily: 'Rubik',
                  boxShadow: 'none',
                  fontWeight: 700
                }}
                sx={{ my: 1 }}
                disabled={item.accepted || loading}
                size="small"
                variant="contained"
                onClick={onResend}
              >
                <Typography
                  style={{
                    fontSize: '14px',
                    color: '#562C82',
                    fontFamily: 'Rubik',
                    fontWeight: 700
                  }}
                >
                  {t('Resend')}
                </Typography>

                <img
                  src="/static/img/icons/reset.svg"
                  alt="resend-icon"
                  style={{
                    width: '18px',
                    height: '18px',
                    marginLeft: '7px',
                    cursor: 'pointer'
                  }}
                />
              </Button>
              <Button
                sx={{ my: 1 }}
                style={{
                  maxWidth: '193px !important',
                  width: '100%',
                  backgroundColor: '#FF6868',
                  color: 'white',
                  borderRadius: '30px',
                  height: '36px'
                }}
                disabled={loading}
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t('Delete')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        onDelete={() => onDelete()}
      />
    </Card>
  );
};

const Invitations = () => {
  const [period, setPeriod] = useState<string>('');
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const { filters, response, loading, subLoading }: any = useSelector(
    (state: RootState) => state.invitations
  );
  const { page, limit, offset, role } = filters;

  const { t } = useLocales();
  const dispatch = useDispatch();

  const { isAdmin, isRecruiterPlus, isRecruiter, isHiringManager } = useAuth();

  const allowedRoles = (() => {
    if (isAdmin) {
      return ROLE_CHOICES;
    }
    if (isRecruiter || isRecruiterPlus) {
      return ROLE_CHOICES.filter((role) =>
        ['hiring_manager', 'reviewer', 'candidate', 'employee'].includes(
          role.value
        )
      );
    }
    if (isHiringManager) {
      return ROLE_CHOICES.filter((role) =>
        ['candidate', 'employee'].includes(role.value)
      );
    }

    return [];
  })();

  useEffect(() => {
    dispatch(getInvitationsData({ limit, offset, role }));
  }, [dispatch, limit, offset, role]);

  useEffect(() => {
    dispatch(setInvitationsFilter('offset', page * limit - limit));
  }, [dispatch, page, limit]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (values: InvitationType) => {
    dispatch(addInvitation(values));
    onClose();
  };

  const onDelete = (id: ID) => dispatch(removeInvitation(id));

  const onResend = (id: ID) =>
    resendInvitation(id).then((res) => {
      if (res) {
        enqueueSnackbar(res.message || t('Email have been resent'), {
          variant: 'success'
        });
      }
    });
  const styleButton = {
    background: 'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)',
    borderRadius: '30px',
    color: 'white',
    fontFamily: 'Rubik',
    padding: '10px 40px 10px 40px'
  };
  const modalStyle = {
    minWidth: '430px'
  };

  const setOption = (role: string) => {
    setPeriod(role);
    dispatch(setInvitationsFilter('role', role));
  };

  return (
    <>
      <Typography variant="h2" marginTop="30px" color="#666" textAlign="center">
        {t('INTELLIGENT RECRUITMENT STARTS TODAY!')}
      </Typography>
      <MFilter
        style={{
          marginTop: '24px',
          marginBottom: '34px',
          display: 'flex',
          justifyContent: 'center',
          width: '100% !important',
          maxWidth: '98px !important'
        }}
        billing={true}
        activeOption={period}
        options={allowedRoles}
        setOption={setOption}
        isInvitation
      />
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box style={{ width: '500px' }}>
          <HeadingContainer
            invitations={true}
            title={t('Your Invitations')}
            subtitle={t('Here you invite people to join')}
          />
        </Box>
        <Button onClick={onOpen} style={styleButton}>
          {t('New Invitations')}
        </Button>
      </Box>
      <Dialog onClose={onClose} open={open} style={modalStyle}>
        <Grid
          style={{
            padding: '40px 40px 0 40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            style={{ fontFamily: 'Rubik', fontWeight: 600, fontSize: '20px' }}
          >
            {t('Create new Invitation')}
          </Typography>
          <Grid onClick={onClose}>
            <Icon
              icon={closeIcon}
              color="#C9CAD9"
              style={{ width: '25px', height: '25px', cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
        <DialogContent>
          <AddInvitationForm allowedRoles={allowedRoles} onSubmit={onSubmit} />
        </DialogContent>
      </Dialog>
      <ListContainer
        invitation={true}
        loading={loading}
        list={response?.results}
        card={InvitationCard}
        getCardProps={(item: any) => ({
          loading: subLoading,
          onDelete: () => onDelete(item.id),
          onResend: () => onResend(item.id)
        })}
      />
      <PaginationContainer
        page={page}
        count={response.count}
        perPage={limit}
        setPage={(value) => dispatch(setInvitationsFilter('page', value))}
      />
    </>
  );
};

export default Invitations;
